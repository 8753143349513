import React, { useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';

const ContactForm = () => {
  const [fields, setFields] = useState({
    name: '',
    email: '',
    message: '',
    sent: false,
  });

  const handleKeyPress = field => e =>
    setFields({ ...fields, [field]: e.target.value });

  const handleFormSubmit = e => {
    e.preventDefault();

    fetch('/contact', {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `name=${fields.name}&email=${fields.email}&message=${
        fields.message
      }`,
    });

    setFields({ ...fields, sent: true });
  };

  return (
    <form
      name="music-website-contact"
      method="POST"
      className="contact-form"
      data-netlify="true"
      onSubmit={handleFormSubmit}
    >
      <div className="name-email-wrapper">
        <input
          className="name"
          name="name"
          placeholder="Name"
          value={fields.name}
          onChange={handleKeyPress('name')}
          disabled={fields.sent}
        />
        <input
          type="email"
          name="email"
          className="email"
          placeholder="Email"
          value={fields.email}
          onChange={handleKeyPress('email')}
          disabled={fields.sent}
        />
      </div>
      <textarea
        className="message"
        name="message"
        placeholder="Enter a message"
        value={fields.message}
        rows={5}
        onChange={handleKeyPress('message')}
        disabled={fields.sent}
      />
      {fields.sent ? (
        <p className="success-message">
          <IoIosCheckmark className="check-icon" /> Thank you for your message!
        </p>
      ) : (
        <button type="submit">Send message</button>
      )}
    </form>
  );
};

export default ContactForm;
