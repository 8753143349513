import React from 'react';
import { IoIosMail } from 'react-icons/io';
import {
  PageLayout,
  PageLayoutHeader,
  PageLayoutBody,
  PageTitle,
} from '../components/page-layout';
import ContactForm from '../components/contact-form/contact-form';
import SEO from '../components/seo';

const Contact = () => (
  <PageLayout className="contact-background">
    <SEO title="Contact" />
    <PageLayoutHeader />
    <PageTitle>Contact Me</PageTitle>
    <PageLayoutBody className="contact-content">
      <p>
        Want to book Gustavo for your next production, or simply share your
        appreciation for his work? Fill out the form below, and he will get back
        to you as soon as he can.
      </p>
      <ContactForm />

      <div className="text-center">
        <IoIosMail className="contact-icon" />
        <p>contactme@gustavodelpino.com</p>
      </div>
    </PageLayoutBody>
  </PageLayout>
);

export default Contact;
